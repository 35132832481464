/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Button, FormInputField, FormTextField } from "@whitespace/components";
import clsx from "clsx";
import { Formik, Form, FieldArray } from "formik";
import fetch from "node-fetch";
import PropTypes from "prop-types";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DevNotice from "../../../DevNotice";
import { formFields, FormFieldsSchema } from "../../hooks/useEventForm";
import { toFormData } from "../../utils";
import {
  FormRadioGroupField,
  FormReactSelectField,
  FormSelectField,
  FormCheckboxField,
  FormUploadField,
} from "../FormFields";

EventSubmitModule.propTypes = {
  title: PropTypes.node,
  module: PropTypes.shape({
    databaseId: PropTypes.string,
    eventCategories: PropTypes.arrayOf(PropTypes.object),
    eventLocations: PropTypes.arrayOf(PropTypes.object),
    eventOrganizers: PropTypes.arrayOf(PropTypes.object),
    eventUserGroups: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default function EventSubmitModule({ module, title, ...restProps }) {
  const { t, i18n } = useTranslation();
  const collator = useMemo(
    () => new Intl.Collator(i18n.language),
    [i18n.language],
  );
  const formId = module.databaseId;
  const eventCategories = module.eventCategories || [];
  const eventLocations = module.eventLocations || [];
  const eventOrganizers = module.eventOrganizers || [];
  const userGroups = module.eventUserGroups || [];

  if (!userGroups?.length) {
    return (
      <DevNotice>
        No user groups provided for the event form.{" "}
        <code>{`userGroups = ${JSON.stringify(userGroups)}`}</code>
      </DevNotice>
    );
  }

  const [submissionError, setSubmissionError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmitEvent = async (
    {
      image,
      useRcrRules,
      organizer,
      location,
      useCustomLocation,
      occasions,
      useCustomOrganizer,
      rcr_rules,
      ...values
    },
    { resetForm },
  ) => {
    // console.log({
    //   useRcrRules,
    //   organizer,
    //   location,
    //   occasions,
    //   rcr_rules,
    //   ...values,
    // });
    setSubmissionError(null);
    try {
      const data = {
        ...values,
        "occurance-type": values["occurance-type"]
          ? values["occurance-type"]
          : "",
        organizers: [
          useCustomOrganizer
            ? { ...organizer, id: undefined, main_organizer: true }
            : organizer,
        ],
        locations: [
          useCustomLocation ? { ...location, id: undefined } : location,
        ],
        // approve: 0, // For when image support is added
        occasions: !useRcrRules
          ? occasions.map(
              ({
                start_date,
                start_time,
                end_date,
                end_time,
                ...occasion
              }) => ({
                ...occasion,
                start_date: `${start_date} ${start_time}`,
                end_date: `${end_date} ${end_time}`,
                status: "scheduled",
                content_mode: "master",
              }),
            )
          : [],
        rcr_rules: useRcrRules ? rcr_rules : [],
      };

      data.user_groups = userGroups;

      const postEndpoint = `/api/municipio-event-form/forms/${formId}/submissions`;

      // console.log({ postEndpoint, data });

      let body = toFormData({ data: JSON.stringify(data), image });

      const response = await fetch(postEndpoint, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },
        body,
      });
      const { success } = await response.json();
      if (!success) {
        throw new Error();
      }
      resetForm();
      setSubmitted(true);
    } catch (error) {
      // console.log(error);
      setSubmissionError(error);
    }
  };

  return (
    <ModuleWrapper
      title={({ H }) => <H className="event-form-module__title">{title}</H>}
      className="event-form-module"
      {...restProps}
    >
      <div className={clsx("event-form")} {...restProps}>
        {submitted ? (
          <div
            css={css`
              background: var(--color-success);
              color: var(--color-success-text);
              padding: 1rem;
            `}
            ref={(el) => {
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Tack för ditt bidrag! Ditt evenemang kommer att granskas av en
            administratör och publiceras så snart som möjligt.
          </div>
        ) : (
          <Formik
            initialValues={{
              title: "",
              content: "",
              image: null,
              useRcrRules: "",
              occasions: [
                {
                  start_date: "",
                  start_time: "",
                  end_date: "",
                  end_time: "",
                },
              ],
              rcr_rules: [
                {
                  rcr_week_day: "",
                  rcr_weekly_interval: "1",
                  rcr_start_time: "",
                  rcr_end_time: "",
                  rcr_start_date: "",
                  rcr_end_date: "",
                },
              ],
              event_link: "",
              booking_link: "",
              price_adult: "",
              price_student: "",
              price_children: "",
              children_age: "",
              price_senior: "",
              senior_age: "",
              age_group_from: "",
              age_group_to: "",
              useCustomOrganizer: "",
              organizer: {
                id: "",
                name: "",
                phone: "",
                email: "",
              },
              useCustomLocation: "",
              location: {
                id: "",
                name: "",
                street_address: "",
                postal_code: "",
                city: "",
              },
              accessibility: [],
              event_categories: [],
              // tags: [],
              // image: {},
              submitter_email: "",
              submitter_email_repeat: "",
              submitter_phone: "",
            }}
            validationSchema={FormFieldsSchema}
            onSubmit={handleSubmitEvent}
          >
            {({
              values,
              // errors,
              isSubmitting,
            }) => {
              return (
                <Form className={clsx("event-form__form")}>
                  {/* <pre>
                  <code>{JSON.stringify({ values, errors }, null, 2)}</code>
                </pre> */}
                  {/* Title */}
                  {formFields.title && !formFields.title.hidden && (
                    <FormInputField
                      type="text"
                      name={formFields.title.name}
                      id={formFields.title.name}
                      label={formFields.title.label}
                      description={
                        !formFields.title.hidden_description &&
                        formFields.title.description
                      }
                      placeholder={formFields.title.placeholder}
                      required={formFields.title.required}
                      className={clsx(
                        "event-form__field",
                        formFields.title.required &&
                          "event-form__field--required",
                      )}
                    />
                  )}
                  {/*  Content */}
                  {formFields.content && !formFields.content.hidden && (
                    <FormTextField
                      name={formFields.content.name}
                      id={formFields.content.name + " textarea"}
                      label={formFields.content.label}
                      description={
                        !formFields.content.hidden_description &&
                        formFields.content.description
                      }
                      rows="10"
                      col="4"
                      required={formFields.content.required}
                      className={clsx(
                        "event-form__field",
                        formFields.content.required &&
                          "event-form__field--required",
                      )}
                    />
                  )}
                  {/* Image */}
                  <FormUploadField
                    name={"image"}
                    label={"Bild"}
                    // description={""}
                    required={true}
                    className={clsx(
                      "event-form__field",
                      "event-form__field--required",
                    )}
                    accept={"image/jpeg, image/png"}
                  />
                  {/* { Occasions} */}
                  {!formFields.useRcrRules.hidden && (
                    <Fragment>
                      <FormRadioGroupField
                        name={formFields.useRcrRules.name}
                        label={formFields.useRcrRules.label}
                        description={
                          !formFields.useRcrRules.hidden_description &&
                          formFields.useRcrRules.description
                        }
                        required={formFields.useRcrRules.required}
                        className={clsx(
                          "event-form__field",
                          "event-form__field--radio",
                          formFields.useRcrRules.required &&
                            "event-form__field--required",
                        )}
                        options={formFields.useRcrRules.options}
                      />
                      {!values.useRcrRules ? (
                        <FieldArray name="occasions" key="occasions">
                          {({ push, remove }) => (
                            <div className="event-form__single-event">
                              <p className="event-form__field-label">
                                {"Enstaka tillfällen"}
                              </p>
                              <div className="event-form__field-wrapper">
                                {values["occasions"].map((item, index) => (
                                  <div
                                    className="event-form__field-group"
                                    key={`occasions.${index}`}
                                  >
                                    <div>
                                      <FormInputField
                                        type="date"
                                        label="Startdatum"
                                        name={`occasions.${index}.start_date`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Starttid"
                                        name={`occasions.${index}.start_time`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="date"
                                        label="Slutdatum"
                                        name={`occasions.${index}.end_date`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Sluttid"
                                        name={`occasions.${index}.end_time`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                    </div>
                                    {index !== 0 && (
                                      <Button onClick={() => remove(index)}>
                                        {t("Radera tillfället")}
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>

                              <Button
                                css={css`
                                  margin-top: 1rem;
                                `}
                                onClick={() => {
                                  push({
                                    start_date: "",
                                    start_time: "",
                                    end_date: "",
                                    end_time: "",
                                  });
                                }}
                              >
                                {t("Lägg till ett tillfälle")}
                              </Button>
                            </div>
                          )}
                        </FieldArray>
                      ) : (
                        <FieldArray name="rcr_rules" key="rcr_rules">
                          {({ push, remove }) => (
                            <div className="event-form__single-event">
                              <p className="event-form__field-label">
                                {"Schema för återkommande evenemang"}
                              </p>
                              <div className="event-form__field-wrapper">
                                {values["rcr_rules"].map((item, index) => (
                                  <div
                                    className="event-form__field-group"
                                    key={`rcr_rules.${index}`}
                                  >
                                    <div>
                                      <FormSelectField
                                        label="Veckodag"
                                        description="Evenemanget inträffar på vald veckodag."
                                        name={`rcr_rules.${index}.rcr_week_day`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                        options={[
                                          { value: "Monday", label: "Måndag" },
                                          { value: "Tuesday", label: "Tisdag" },
                                          {
                                            value: "Wednesday",
                                            label: "Onsdag",
                                          },
                                          {
                                            value: "Thursday",
                                            label: "Torsdag",
                                          },
                                          { value: "Friday", label: "Fredag" },
                                          {
                                            value: "Saturday",
                                            label: "Lördag",
                                          },
                                          { value: "Sunday", label: "Söndag" },
                                        ]}
                                      />
                                      <FormInputField
                                        type="number"
                                        label="Veckointervall"
                                        description="Ange med vilken veckointervall händelsen inträffar. 1 motsvarar varje vecka."
                                        min="1"
                                        name={`rcr_rules.${index}.rcr_weekly_interval`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Starttid"
                                        name={`rcr_rules.${index}.rcr_start_time`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="time"
                                        label="Sluttid"
                                        name={`rcr_rules.${index}.rcr_end_time`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="date"
                                        label="Från och med datum"
                                        name={`rcr_rules.${index}.rcr_start_date`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                      <FormInputField
                                        type="date"
                                        label="Till och med datum"
                                        name={`rcr_rules.${index}.rcr_end_date`}
                                        className={clsx("event-form__field")}
                                        css={css({
                                          "--field-width": "50%",
                                        })}
                                      />
                                    </div>
                                    {index !== 0 && (
                                      <Button onClick={() => remove(index)}>
                                        {t("Radera schemat")}
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>

                              <Button
                                css={css`
                                  margin-top: 1rem;
                                `}
                                onClick={() => {
                                  push({
                                    rcr_week_day: "",
                                    rcr_weekly_interval: "1",
                                    rcr_start_time: "",
                                    rcr_end_time: "",
                                    rcr_start_date: "",
                                    rcr_end_date: "",
                                  });
                                }}
                              >
                                {t("Lägg till ett schema")}
                              </Button>
                            </div>
                          )}
                        </FieldArray>
                      )}
                    </Fragment>
                  )}
                  {/*  Event link */}
                  {formFields.event_link && !formFields.event_link.hidden && (
                    <FormInputField
                      type="text"
                      inputMode="url"
                      name={formFields.event_link.name}
                      id={formFields.event_link.name}
                      label={formFields.event_link.label}
                      description={
                        !formFields.event_link.hidden_description &&
                        formFields.event_link.description
                      }
                      placeholder={formFields.event_link.placeholder}
                      required={formFields.event_link.required}
                      className={clsx(
                        "event-form__field",
                        formFields.event_link.required &&
                          "event-form__field--required",
                      )}
                      inputProps={formFields.event_link.inputProps}
                    />
                  )}
                  {/*  Booking link */}
                  {formFields.booking_link &&
                    !formFields.booking_link.hidden && (
                      <FormInputField
                        type="text"
                        inputMode="url"
                        name={formFields.booking_link.name}
                        id={formFields.booking_link.name}
                        label={formFields.booking_link.label}
                        description={
                          !formFields.booking_link.hidden_description &&
                          formFields.booking_link.description
                        }
                        placeholder={formFields.booking_link.placeholder}
                        required={formFields.booking_link.required}
                        className={clsx(
                          "event-form__field",
                          formFields.booking_link.required &&
                            "event-form__field--required",
                        )}
                      />
                    )}
                  {/*  Price adult */}
                  {formFields.price_adult && !formFields.price_adult.hidden && (
                    <FormInputField
                      type="number"
                      name={formFields.price_adult.name}
                      id={formFields.price_adult.name}
                      label={formFields.price_adult.label}
                      description={
                        !formFields.price_adult.hidden_description &&
                        formFields.price_adult.description
                      }
                      placeholder={formFields.price_adult.placeholder}
                      required={formFields.price_adult.required}
                      className={clsx(
                        "event-form__field",
                        formFields.price_adult.required &&
                          "event-form__field--required",
                      )}
                    />
                  )}
                  {/*  Price student: */}
                  {formFields.price_student &&
                    !formFields.price_student.hidden && (
                      <FormInputField
                        type="number"
                        name={formFields.price_student.name}
                        id={formFields.price_student.name}
                        label={formFields.price_student.label}
                        description={
                          !formFields.price_student.hidden_description &&
                          formFields.price_student.description
                        }
                        placeholder={formFields.price_student.placeholder}
                        required={formFields.price_student.required}
                        className={clsx(
                          "event-form__field",
                          formFields.price_student.required &&
                            "event-form__field--required",
                        )}
                      />
                    )}
                  {/* Price children */}
                  {formFields.price_children &&
                    !formFields.price_children.hidden && (
                      <FormInputField
                        type="number"
                        name={formFields.price_children.name}
                        id={formFields.price_children.name}
                        label={formFields.price_children.label}
                        description={
                          !formFields.price_children.hidden_description &&
                          formFields.price_children.description
                        }
                        placeholder={formFields.price_children.placeholder}
                        required={formFields.price_children.required}
                        className={clsx(
                          "event-form__field",
                          formFields.price_children.required &&
                            "event-form__field--required",
                        )}
                      />
                    )}
                  {/* Children age */}
                  {formFields.children_age &&
                    !formFields.children_age.hidden && (
                      <FormInputField
                        type="number"
                        name={formFields.children_age.name}
                        id={formFields.children_age.name}
                        label={formFields.children_age.label}
                        description={
                          !formFields.children_age.hidden_description &&
                          formFields.children_age.description
                        }
                        placeholder={formFields.children_age.placeholder}
                        required={formFields.children_age.required}
                        className={clsx(
                          "event-form__field",
                          formFields.children_age.required &&
                            "event-form__field--required",
                        )}
                      />
                    )}
                  {/* Price senior */}
                  {formFields.price_senior &&
                    !formFields.price_senior.hidden && (
                      <FormInputField
                        type="number"
                        name={formFields.price_senior.name}
                        id={formFields.price_senior.name}
                        label={formFields.price_senior.label}
                        description={
                          !formFields.price_senior.hidden_description &&
                          formFields.price_senior.description
                        }
                        placeholder={formFields.price_senior.placeholder}
                        required={formFields.price_senior.required}
                        className={clsx(
                          "event-form__field",
                          formFields.price_senior.required &&
                            "event-form__field--required",
                        )}
                      />
                    )}
                  {/* Senior age */}
                  {formFields.senior_age && !formFields.senior_age.hidden && (
                    <FormInputField
                      type="number"
                      name={formFields.senior_age.name}
                      id={formFields.senior_age.name}
                      label={formFields.senior_age.label}
                      description={
                        !formFields.senior_age.hidden_description &&
                        formFields.senior_age.description
                      }
                      placeholder={formFields.senior_age.placeholder}
                      required={formFields.senior_age.required}
                      className={clsx(
                        "event-form__field",
                        formFields.senior_age.required &&
                          "event-form__field--required",
                      )}
                    />
                  )}
                  {/* Age group */}
                  {!formFields.age_group.hidden && (
                    <fieldset className="event-form__field-wrapper event-form__field-group-fieldset">
                      <legend>
                        <span className="event-form__field-label">
                          {" "}
                          {formFields.age_group.label}
                        </span>
                        {!formFields.age_group.hidden_description &&
                          formFields.age_group.description && (
                            <span className="event-form__field-description">
                              {formFields.age_group.description}
                            </span>
                          )}
                      </legend>
                      <div className="event-form__field-group">
                        <div>
                          {formFields.age_group.fields.map((child, index) => (
                            <FormInputField
                              css={css({
                                "--field-width": child.width,
                              })}
                              type="number"
                              key={index}
                              name={child.name}
                              id={child.name}
                              label={child.label}
                              description={
                                !child.hidden_description && child.description
                              }
                              placeholder={child.placeholder}
                              required={child.required}
                              className={clsx(
                                "event-form__field",
                                child.required && "event-form__field--required",
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    </fieldset>
                  )}

                  {/* Organizer */}
                  {!formFields.organizer.hidden && (
                    <Fragment>
                      <FormRadioGroupField
                        name={"useCustomOrganizer"}
                        label="Arrangör"
                        description="Skriv arrangörens namn och välj från de förslag som vi ger dig. Dyker inte arrangörens namn upp, kan du nämna det i din beskrivning."
                        required={formFields["organizer-selector"].required}
                        className={clsx(
                          "event-form__field",
                          "event-form__field--radio",
                          formFields["organizer-selector"].required &&
                            "event-form__field--required",
                        )}
                        options={[
                          { value: "", label: "Befintlig arrangör" },
                          { value: "yes", label: "Ny arrangör" },
                        ]}
                      />
                      {!values.useCustomOrganizer ? (
                        <FormReactSelectField
                          name="organizer.id"
                          label="Välj arrangör"
                          options={
                            eventOrganizers?.length
                              ? eventOrganizers
                                  .map((option) => ({
                                    value: option.id,
                                    label: option.name || option.title,
                                  }))
                                  .sort((a, b) =>
                                    collator.compare(a.label, b.label),
                                  )
                              : []
                          }
                          className={clsx("event-form__field")}
                          fieldProps={{
                            isSearchable: true,
                          }}
                        />
                      ) : (
                        <div className="event-form__field-group">
                          <div>
                            <FormInputField
                              type="text"
                              name="organizer.name"
                              label="Namn"
                              className={clsx(
                                "event-form__field",
                                // item.required && "event-form__field--required",
                              )}
                              // css={css({
                              //   "--field-width": "100%",
                              // })}
                            />
                            <FormInputField
                              type="text"
                              name="organizer.phone"
                              label="Telefonnummer"
                              className={clsx("event-form__field")}
                            />
                            <FormInputField
                              type="email"
                              name="organizer.email"
                              label="E-postadress"
                              className={clsx("event-form__field")}
                            />
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}

                  {/* Location  */}
                  {!formFields.location.hidden && (
                    <Fragment>
                      <FormRadioGroupField
                        name={"useCustomLocation"}
                        label="Plats"
                        description="Skriv platsens namn och välj från de förslag som vi ger dig. Dyker inte platsens namn upp, kan du nämna det i din beskrivning."
                        required={formFields["organizer-selector"].required}
                        className={clsx(
                          "event-form__field",
                          "event-form__field--radio",
                          formFields["organizer-selector"].required &&
                            "event-form__field--required",
                        )}
                        options={[
                          { value: "", label: "Befintlig plats" },
                          { value: "yes", label: "Ny plats" },
                        ]}
                      />
                      {!values.useCustomLocation ? (
                        <FormReactSelectField
                          name="location.id"
                          label="Välj plats"
                          options={
                            eventLocations?.length
                              ? eventLocations
                                  .map((option) => ({
                                    value: option.id,
                                    label: option.name || option.title,
                                  }))
                                  .sort((a, b) =>
                                    collator.compare(a.label, b.label),
                                  )
                              : []
                          }
                          className={clsx("event-form__field")}
                          fieldProps={{
                            isSearchable: true,
                          }}
                        />
                      ) : (
                        <div className="event-form__field-group">
                          <div>
                            <FormInputField
                              type="text"
                              name="location.name"
                              label="Namn"
                              className={clsx("event-form__field")}
                            />
                            <FormInputField
                              type="text"
                              name="location.street_address"
                              label="Gatuadress"
                              className={clsx("event-form__field")}
                            />
                            <FormInputField
                              type="text"
                              name="location.postal_code"
                              label="Postnummer"
                              className={clsx("event-form__field")}
                              css={css({
                                "--field-width": "40%",
                              })}
                            />
                            <FormInputField
                              type="text"
                              name="location.city"
                              label="Ort"
                              className={clsx("event-form__field")}
                              css={css({
                                "--field-width": "60%",
                              })}
                            />
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}

                  {/* Accessibility */}
                  {!formFields.accessibility.hidden && (
                    <Fragment>
                      <FormCheckboxField
                        name={formFields.accessibility.name}
                        id={formFields.accessibility.name}
                        label={formFields.accessibility.label}
                        description={
                          !formFields.accessibility.hidden_description &&
                          formFields.accessibility.description
                        }
                        required={formFields.accessibility.required}
                        className={clsx(
                          "event-form__field",
                          "event-form__field--radio",
                          formFields.accessibility.required &&
                            "event-form__field--required",
                        )}
                        options={formFields.accessibility.options}
                      />
                    </Fragment>
                  )}

                  {/* Categories */}
                  {!formFields.categories.hidden && (
                    <Fragment>
                      <FormReactSelectField
                        name="event_categories"
                        label="Kategorier"
                        description="Välj passande kategorier för ditt evenemang eller aktivitet."
                        options={
                          eventCategories?.length
                            ? eventCategories
                                .map((option) => ({
                                  value: option.id,
                                  label: option.name || option.title,
                                }))
                                .sort((a, b) =>
                                  collator.compare(a.label, b.label),
                                )
                            : []
                        }
                        required={formFields.categories.required}
                        className={clsx(
                          "event-form__field",
                          formFields.categories.required &&
                            "event-form__field--required",
                        )}
                        multiple={true}
                        fieldProps={{
                          isSearchable: true,
                        }}
                      />
                    </Fragment>
                  )}

                  {/* Tags */}
                  {/* {!formFields.tags.hidden && (
                  <Fragment>
                    <TaxonomyComponent
                      {...formFields.tags}
                      handleFieldValue={setFieldValue}
                      value={values.tags}
                    />
                  </Fragment>
                )} */}
                  {/* OG CODE */}
                  {/* <div class="form-group gutter creamy image-box text-center">
                      <p><i class="pricon pricon-badge pricon-badge-red-3 pricon-3x pricon-picture"></i></p>
                      <p>
                          <strong>{{ $image_input->label }} {!! $image_input->required ? '<span class="text-danger">*</span></label>' : '' !!}</strong>
                      </p>

                      <p>
                          @if(!$image_input->hidden_description)
                              <small class="text-dark-gray">{!! $image_input->description !!}</small>
                          @endif
                      </p>
                      <p>
                          <button class="btn btn-secondary img-button">{{ $image_input->label }}</button>
                      </p>
                  </div>
                  <div class="form-group gutter creamy image-approve" style="display:none;">
                      <label><?php _e('Before you can upload an image, you need to confirm the following terms', 'event-integration'); ?></label>

                      <p>
                        <label class="checkbox">
                            <input type="checkbox" name="approve"
                                  id="first-approve"> <?php _e('I have the right to use this image to promote this event.', 'event-integration'); ?>
                        </label>
                      </p>
                      <p>
                        <?php _e('Are there identifiable persons on the image/images?', 'event-integration'); ?>
                      </p>

                      <p>
                        <label class="radio">
                            <input type="radio" name="approve" value="1"> <?php _e('Yes'); ?>
                        </label>
                        <label class="radio">
                            <input type="radio" name="approve" value="0"> <?php _e('No'); ?>
                        </label>
                      </p>

                      <p>
                        <div id="persons-approve" class="hidden">
                            <label class="checkbox">
                                <input type="checkbox" name="approve"
                                      id="second-approve"> <?php _e('They have accepted that the image is used to promote this event and have been informed that after the image has been added to the database, it may appear in different channels to promote the event.', 'event-integration'); ?>
                            </label>
                        </div>
                      </p>

                  </div>
                  <div class="form-group image-upload" style="display:none;">
                      <label for="{{ $image_input->name }}"><?php _e('Upload an image', 'event-integration'); ?> {!! $image_input->required ? '<span class="text-danger">*</span></label>' : '' !!}</label>
                      <input name="{{ $image_input->name }}"
                            id="{{ $image_input->name }}"
                            type="file"
                            accept="image/gif, image/jpeg, image/png"
                              {{ $image_input->required ? 'required' : '' }}>
                  </div> */}

                  {/* KONTAKTINFORMATION */}
                  {((formFields.email && !formFields.email.hidden) ||
                    (formFields.phone && !formFields.phone.hidden)) && (
                    <fieldset className="event-form__field-wrapper event-form__field-group-fieldset">
                      <legend>
                        <span className="event-form__field-label">
                          Kontaktinformation
                        </span>
                        <span>
                          Ange dina kontaktuppgifter nedan för eventuella
                          frågor.
                        </span>
                      </legend>
                      {/* Email */}
                      {formFields.email && !formFields.email.hidden && (
                        <FormInputField
                          type="email"
                          name={formFields.email.name}
                          id={formFields.email.name}
                          label={formFields.email.label}
                          description={
                            !formFields.email.hidden_description &&
                            formFields.email.description
                          }
                          inputProps={formFields.email.inputProps}
                          required={formFields.email.required}
                          className={clsx(
                            "event-form__field",
                            formFields.email.required &&
                              "event-form__field--required",
                          )}
                        />
                      )}
                      {/* Email Repeat */}
                      {formFields.email_repeat &&
                        !formFields.email_repeat.hidden && (
                          <FormInputField
                            type="email"
                            name={formFields.email_repeat.name}
                            id={formFields.email_repeat.name}
                            label={formFields.email_repeat.label}
                            description={
                              !formFields.email_repeat.hidden_description &&
                              formFields.email_repeat.description
                            }
                            inputProps={formFields.email_repeat.inputProps}
                            required={formFields.email_repeat.required}
                            className={clsx(
                              "event-form__field",
                              formFields.email_repeat.required &&
                                "event-form__field--required",
                            )}
                          />
                        )}
                      {/* Phone */}
                      {formFields.phone && !formFields.phone.hidden && (
                        <FormInputField
                          type="text"
                          name={formFields.phone.name}
                          id={formFields.phone.name}
                          label={formFields.phone.label}
                          description={
                            !formFields.phone.hidden_description &&
                            formFields.phone.description
                          }
                          inputProps={formFields.phone.inputProps}
                          required={formFields.phone.required}
                          className={clsx(
                            "event-form__field",
                            formFields.phone.required &&
                              "event-form__field--required",
                          )}
                        />
                      )}
                    </fieldset>
                  )}
                  <div className="event-form__field-submit">
                    {/* <p className="event-form__field-description">
                    Klar? Klicka här för att skicka in ditt evenemang för
                    granskning.
                  </p> */}
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? "Skickar…" : "Skicka evenemang"}
                    </Button>
                    {!!submissionError && (
                      <div
                        css={css`
                          background: var(--color-danger);
                          color: var(--color-danger-text);
                          padding: 1rem;
                          margin-top: 1rem;
                        `}
                      >
                        {t(
                          `Ett fel uppstod. Se till att alla obligatoriska fält har fyllts i.`,
                        )}
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </ModuleWrapper>
  );
}
